<template>
  <div class="page">
    <div class="classify">安装信息（必填）</div>
    <div class="blcok_box">
      <div class="equipment_box">
        <div class="left_box">安装设备</div>
        <div class="check_box">
          <!-- <div class="select_box" @click="equipmentChoose(0)"> -->
          <div class="select_box">
            <!-- <input type="checkbox" /> -->
            <img src="./img/select.png" alt="" />
          </div>
          <div class="value">智能门磁</div>
        </div>
        <div class="check_box">
          <!-- <div class="select_box" @click="equipmentChoose(1)"> -->
          <div class="select_box">
            <!-- <input type="checkbox" /> -->
            <img src="./img/select.png" alt="" />
          </div>
          <div class="value">无线紧急按钮</div>
        </div>
      </div>
      <div class="equipment_box" style="border: none">
        <div class="left_box" style="color: #fff">安装设备</div>
        <div class="check_box">
          <!-- <div class="select_box" @click="equipmentChoose(2)"> -->
          <div class="select_box">
            <!-- <input type="checkbox" /> -->
            <img src="./img/select.png" alt="" />
          </div>
          <div class="value">无线报警主机</div>
        </div>
        <div class="check_box">
          <!-- <div class="select_box" @click="equipmentChoose(3)"> -->
          <div class="select_box">
            <!-- <input type="checkbox" /> -->
            <img src="./img/select.png" alt="" />
          </div>
          <div class="value">定时抄表拍照终端</div>
        </div>
      </div>
      <div class="input_box">
        <div class="label">长者姓名</div>
        <div class="input">
          <input
            v-model="form.elderName"
            type="text"
            placeholder="请输入姓名"
          />
        </div>
      </div>
      <!-- <div class="input_box">
        <div class="label">长者年龄</div>
        <div class="input">
          <input
            v-model="form.elderAge"
            type="number"
            placeholder="请输入年龄（80岁以上老人才可申请）"
          />
        </div>
      </div> -->
      <div class="input_box">
        <div class="label">长者身份证</div>
        <div class="input">
          <input
            v-model="form.elderIdNumber"
            type="text"
            placeholder="请输入身份证号码"
          />
        </div>
      </div>
      <div class="input_box">
        <div class="label">联系电话</div>
        <div class="input">
          <input
            v-model="form.mobileNum"
            type="text"
            placeholder="请输入电话号码"
          />
        </div>
      </div>
      <div
        class="item_box"
        v-for="(item, index) in this.selectList"
        :key="index"
        @click="selectValue(item, index)"
      >
        <div class="left_box">
          {{ index >= 0 ? item.title + "(必填)" : item.title }}
        </div>
        <div class="right_box">{{ item.text || "请选择" + item.title }}</div>
      </div>
    </div>
    <!-- 2 -->
    <div class="classify">紧急联系人（必填）</div>
    <div class="blcok_box">
      <div class="input_box">
        <div class="label">联系人1</div>
        <div class="input">
          <input
            v-model="form.emergencyContactName"
            type="text"
            placeholder="请输入姓名"
          />
        </div>
      </div>
      <div class="input_box" style="border: none">
        <div class="label">电话1</div>
        <div class="input">
          <input
            v-model="form.emergencyContactMobile"
            type="text"
            placeholder="请输入电话号码"
          />
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="classify">备选联系人（选填）</div>
    <div class="blcok_box">
      <div class="input_box">
        <div class="label">联系人2</div>
        <div class="input">
          <input
            v-model="form.standbyContactName"
            type="text"
            placeholder="请输入姓名"
          />
        </div>
      </div>
      <div class="input_box" style="border: none">
        <div class="label">电话2</div>
        <div class="input">
          <input
            v-model="form.standbyContactMobile"
            type="text"
            placeholder="请输入电话号码"
          />
        </div>
      </div>
    </div>
    <div class="btn" @click="submit">提交申请</div>
    <v-picker
      :columns="list"
      :valueShow="show"
      :valueKey="eventKey"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import { applyUrl, areaLinkUrl } from "./api.js";
export default {
  name: "safetyShieldApply",
  data() {
    return {
      equipmentList: [
        { name: "智能门磁", flag: false },
        { name: "无线紧急按钮", flag: false },
        { name: "无线报警主机", flag: false },
        { name: "定时抄表拍照终端", flag: false },
      ],
      form: {
        applyEquipment: [],
        elderName: "",
        elderIdNumber: "",
        mobileNum: "",
        emergencyContactName: "",
        emergencyContactMobile: "",
        standbyContactName: "",
        standbyContactMobile: "",
      },

      selectList: [],
      idList: [],
      textList: [],

      list: [],
      show: false,
      eventKey: "text",

      itemIndexNum: 0,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.getItemData();
  },
  methods: {
    selectValue(item, index) {
      this.show = true;
      this.list = item.list;
      this.itemIndexNum = index;
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      if (value.text == this.selectList[this.itemIndexNum].text) {
      } else {
        if (this.itemIndexNum + 1 == this.selectList.length) {
          this.selectList[this.itemIndexNum].code = value.code;
          this.selectList[this.itemIndexNum].text = value.text;
          this.getItemData(value.code);
        } else {
          this.selectList[this.itemIndexNum].code = value.code;
          this.selectList[this.itemIndexNum].text = value.text;
          this.selectList.forEach((item, index) => {
            if (index > this.itemIndexNum) {
              item.code = null;
              item.text = null;
            }
          });
        }
      }
      this.show = false;
    },
    getItemData(parentId) {
      let params = {
        parentId: parentId || this.tenantId,
        specifyCollectionTypes: 1,
        fromFlag: 1,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${areaLinkUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (Object.getOwnPropertyNames(res.data).length == 0) {
            } else {
              this.selectList.push(res.data);
            }
          }
        });
    },
    // 提交申请按钮
    submit() {
      let textList = [];
      this.selectList.forEach((item) => {
        if (item.text) {
          textList.push(item.text);
        }
      });
      // if (this.form.applyEquipment.length == 0) {
      //   this.$toast("请选择安装设备！");
      //   return;
      // }
      if (this.form.elderName == "") {
        this.$toast("长者姓名不能为空！");
        return;
      }
      if (this.form.elderIdNumber == "") {
        this.$toast("长者身份证不能为空！");
        return;
      }
      if (this.form.elderIdNumber.length != 18) {
        this.$toast("请输入正确的长者身份证！");
        return;
      }
      if (this.form.mobileNum == "") {
        this.$toast("联系电话不能为空！");
        return;
      }
      if (!this.selectList[this.selectList.length - 1].code) {
        this.$toast("安装地址房号不能为空！");
        return;
      }
      if (this.form.emergencyContactName == "") {
        this.$toast("紧急联系人姓名不能为空！");
        return;
      }
      if (this.form.emergencyContactMobile == "") {
        this.$toast("紧急联系人电话不能为空！");
        return;
      }
      if (this.form.emergencyContactMobile.length > 11) {
        this.$toast("请输入正确的紧急联系人电话格式！");
        return;
      }
      let list = [];
      this.equipmentList.forEach((item) => {
        list.push(item.name);
      });
      let params = {
        userId: this.userId,
        // applyEquipment: this.form.applyEquipment.join(","),
        applyEquipment: list.join(","),
        elderName: this.form.elderName,
        elderIdNumber: this.form.elderIdNumber,
        mobileNum: this.form.mobileNum,
        detailedAddress: textList.join(""),
        houseId: this.selectList[this.selectList.length - 1].code,
        emergencyContactName: this.form.emergencyContactName,
        emergencyContactMobile: this.form.emergencyContactMobile,
        standbyContactName: this.form.standbyContactName,
        standbyContactMobile: this.form.standbyContactMobile,
      };
      this.$axios.post(`${applyUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast(res.msg);
          setTimeout(() => {
            this.$router.push({
              name: "safetyShieldApplySuccess",
            });
          }, 800);
        }
      });
    },
    // 选择安装设备
    equipmentChoose(index) {
      this.equipmentList[index].flag = !this.equipmentList[index].flag;
      this.form.applyEquipment = [];
      this.equipmentList.forEach((item) => {
        if (item.flag == true) {
          this.form.applyEquipment.push(item.name);
        }
      });
      console.log(this.form.applyEquipment.join(","));
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding: 0px 30px;
  padding-bottom: 74px;
  box-sizing: border-box;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #72a8ff 0%, #2f7cff 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 62px;
  }
  .blcok_box {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
    border-radius: 16px;
    padding: 20px 30px;
    box-sizing: border-box;
    .item_box {
      width: 100%;
      line-height: 100px;
      height: 100%;
      font-size: 30px;
      display: flex;
      border-top: 1px solid #eeeeee;

      .left_box {
        width: 200px;
        height: 100%;
        color: #333333;
      }

      .right_box {
        text-align: right;
        flex: 1;
        height: 100%;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .input_box {
      width: 100%;
      height: 98px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #eeeeee;

      .label {
        width: 200px;
        font-size: 30px;
        color: #333333;
      }

      .input {
        flex: 1;
        height: 100%;

        input {
          width: 100%;
          height: 100%;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          text-align: right;
        }
      }
    }

    .equipment_box {
      width: 100%;
      height: 98px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #eeeeee;

      .left_box {
        width: 150px;
        font-size: 30px;
        color: #333333;
      }

      .check_box {
        flex: 1;
        display: flex;
        align-items: center;

        .select_box {
          width: 32px;
          height: 32px;
          /deep/input[type="checkbox"] {
            width: 100%;
            height: 100%;
            border-color: #307dff !important;
            color: #307dff;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }

        .value {
          margin-left: 12px;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .classify {
    font-size: 30px;
    font-weight: 600;
    color: #333333;
    padding: 34px 0;
  }
}
</style>
